<template>
  <div>
    <Header v-if="embed === 'false' || embed === undefined"/>

    <FetchDados />

    <GerarCertificados />
  </div>
</template>

<script>
import FetchDados from "./components/FetchDados.vue";
import Header from "./components/Header.vue";
import GerarCertificados from "./components/GerarCertificados.vue";

export default {
  components: {
    FetchDados,
    Header,
    GerarCertificados
  },
  computed: {
    embed () {
     let url = window.location.href;
      url = url.split("&embed=");
      url = url[1];
      return url
    }
  }
};

</script>

<style scoped>
body {
  background-size: 650%;
  overflow: auto;
}
</style>
