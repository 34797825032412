/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vuex from "vuex";
import store from "./store";
import http from "@/http";
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.prototype.$http = http;

require("./assets/css/certificado.css");
// require("./assets/css/dncscore.css");
require("./assets/css/global.css");
require("./assets/css/main.css");
// require("./assets/css/softcheck.css");

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(Vuex);

// Configuração do APM
import { init as initApm } from '@elastic/apm-rum';

Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(App),
  created() {
    try {
      let environment = 'production'; // Valor padrão para ambiente de produção
      
      // Verifica se a variável de ambiente NUXT_ENV está definida e atribui o valor correspondente
      if (process.env.NUXT_ENV) {
        environment = process.env.NUXT_ENV === 'homolog' ? 'development' : 'production';
      }
      
      const apm = initApm({
        serviceName: 'certificados',
        serverUrl: 'https://apm-elastic.dnc.group:8200',
        environment: environment
      });
      Vue.prototype.$apm = apm;
    } catch (error) {
      console.log(error);
    }
  }
}).$mount('#app');
