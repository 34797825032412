<template>
  <div></div>
</template>

<script>
import { mapMutations } from "vuex";
// const axios = require("axios");

export default {
  name: "FetchDados",
  methods: {
    ...mapMutations(["FETCH_DATA", "UPDATE_ALUNO"]),
    async fetchDados() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const aluno_id = urlParams.get('aluno_id')
      this.UPDATE_ALUNO(aluno_id);
      let article = {
        aluno_id: aluno_id
      };

      await this.$http
        .post("verificar/certificados", this.composeObject(article))
        .then(response => {
          this.FETCH_DATA(response.data);
        })
        .catch(() => {
          this.showAlert();
        });
    },
    composeObject(object) {
      var formData = new FormData();
      formData.append("data", JSON.stringify(object));
      return formData;
    },
    showAlert() {
      this.$swal({
        icon: "error",
        title: "Oops...",
        text: "Não conseguimos encontrar suas credenciais!",
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: "Cancelar",
        allowOutsideClick: false,
        closeOnEsc: false
      }).then(result => {
        if (result.isDenied) {
          window.location.href = "https://aluno.dnc.group/dncscore/";
        }
      });
    }
  },
  created() {
    this.fetchDados();
  }
};
</script>
