import axios from "axios";

var url_atual = window.location.href;
let url = url_atual.split("/");
let base = "";

if (url[2] == "localhost:8080") {
  base = "https://api.dinamicatreinamentos.com.dev/"; // Caminho da api local
} else {
  base = "https://api.dinamicatreinamentos.com"; // Caminho da api no servidor
}

const http = axios.create({
  baseURL: base
});

export default http;
