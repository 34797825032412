<template>
  <div>
    <section class="selectCourseList">
      <div v-if="loading" class="flex-sup rodar">
        <img src="../assets/_img/loading.png" />
      </div>
      <ul class="courseListWrapper" v-if="!loading">
        <div class="title-select_tumas">
          <div>
            <h3>Certificados</h3>
          </div>
          <b-container
            fluid
            v-if="data.certificados_habilitados == ''"
            class="withoutCertificate"
          >
            <h4 class="textWithoutCertificate">
              Você ainda não tem certificados.
            </h4>

            <p class="linkWithoutCertificate">
              <a
                class="linkStartCourse"
                href="https://aluno.dnc.group"
                target="_black"
                >Inicie um novo curso.</a
              >

              É super fácil e rápido.
            </p>
          </b-container>
        </div>

        <li v-for="ciclo in data.ciclos" :key="ciclo">
          <div
            class="bloco"
            :class="{ 'certi-desabilitado': !ciclo.habilitado }"
          >
            <strong @click="gerarCertificadoCiclo(ciclo)">
              {{ ciclo.prova_nome }}</strong
            >
            <figure
              @click="gerarCertificadoCiclo(ciclo)"
              class="courseThumb bgcenter"
              :style="{
                backgroundImage:
                  'url( https://dnc.group/_img/_cover/cover' +
                  ciclo.prova_certificado_imagem +
                  '.png) , url( https://dnc.group/_img/_cover/sem_imagem.jpg) '
              }"
            ></figure>
            <a
              class="linkedinLink"
              @click="ciclo.habilitado ? gerarCertificadoLinkedin(ciclo) : null"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              <p>Adicionar ao Perfil</p>
            </a>
          </div>
        </li>
        <li v-if="ip">
          <div
            class="bloco"
            @click="gerarCertificadoImersao('ip')"
            :class="{ 'certi-desabilitado': !ip_habilitado }"
          >
            <strong> Imersão de Projetos </strong>
            <figure
              class="courseThumb bgcenter"
              style="
                  background: url('https://dnc.group/_img/_cover/cover20.jpg');
                "
            ></figure>
            <a
              class="linkedinLink"
              :href="!ip_habilitado ? '' : linkedin_ip"
              :target="!ip_habilitado ? '' : '_blank'"
              :class="{ 'certi-desabilitado': !ip_habilitado }"
              :style="!ip_habilitado ? 'pointer-events: none' : ''"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              Adicionar ao Perfil
            </a>
          </div>
        </li>
        <li v-if="sms">
          <div class="bloco" :class="{ 'certi-desabilitado': !sms_habilitado }">
            <strong> Sales and Marketing Specialist </strong>
            <figure
              @click="gerarCertificadoImersao('sms')"
              class="courseThumb bgcenter"
              style="
                  background: url('https://dnc.group/_img/_cover/cover79.jpg');
                "
            ></figure>
            <a
              class="linkedinLink"
              :href="!sms_habilitado ? '' : linkedin_sms"
              :target="!sms_habilitado ? '' : '_blank'"
              :class="{ 'certi-desabilitado': !sms_habilitado }"
              :style="!sms_habilitado ? 'pointer-events: none' : ''"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              Adicionar ao Perfil
            </a>
          </div>
        </li>
        <li v-if="mbp">
          <div class="bloco" :class="{ 'certi-desabilitado': !mbp_habilitado }">
            <strong> Master Business Program </strong>
            <figure
              @click="gerarCertificadoImersao('mbp')"
              class="courseThumb bgcenter"
              style="
                  background: url('https://dnc.group/_img/_cover/cover21.jpg');
                "
            ></figure>
            <a
              class="linkedinLink"
              :href="!mbp_habilitado ? '' : linkedin_mbp"
              :target="!mbp_habilitado ? '' : '_blank'"
              :class="{ 'certi-desabilitado': !mbp_habilitado }"
              :style="!mbp_habilitado ? 'pointer-events: none' : ''"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              Adicionar ao Perfil
            </a>
          </div>
        </li>
        <li v-if="dex">
          <div class="bloco" :class="{ 'certi-desabilitado': !dex_habilitado }">
            <strong> Data Expert </strong>
            <figure
              @click="gerarCertificadoImersao('dex')"
              class="courseThumb bgcenter"
              style="
                  background: url('https://dnc.group/_img/_cover/cover57.jpg');
                "
            ></figure>
            <a
              class="linkedinLink"
              :href="!dex_habilitado ? '' : linkedin_dex"
              :target="!dex_habilitado ? '' : '_blank'"
              :class="{ 'certi-desabilitado': !dex_habilitado }"
              :style="!dex_habilitado ? 'pointer-events: none' : ''"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              Adicionar ao Perfil
            </a>
          </div>
        </li>
        <li v-if="ppm">
          <div
            class="bloco"
            @click="gerarCertificadoImersao('ppm')"
            :class="{ 'certi-desabilitado': !ppm_habilitado }"
          >
            <strong> Formação em Produto </strong>
            <figure
              class="courseThumb bgcenter"
              style="
                  background: url('https://dnc.group/_img/_cover/cover780.png');
                "
            ></figure>
            <a
              class="linkedinLink"
              :href="!ppm_habilitado ? '' : linkedin_ppm"
              :target="!ppm_habilitado ? '' : '_blank'"
              :class="{ 'certi-desabilitado': !ppm_habilitado }"
              :style="!ppm_habilitado ? 'pointer-events: none' : ''"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              Adicionar ao Perfil
            </a>
          </div>
        </li>
        <li v-if="ambevon">
          <div
            class="bloco"
            @click="gerarCertificadoImersao('ambevon')"
            :class="{ 'certi-desabilitado': !ambevon_habilitado }"
          >
            <strong> Project Manager Full Stack </strong>
            <figure
              class="courseThumb bgcenter"
              style="
                  background: url('https://dnc.group/_img/_cover/cover251.png');"
            ></figure>
            <a
              class="linkedinLink"
              :href="!ambevon_habilitado ? '' : linkedin_ambevon"
              :target="!ambevon_habilitado ? '' : '_blank'"
              :class="{ 'certi-desabilitado': !ambevon_habilitado }"
              :style="!ambevon_habilitado ? 'pointer-events: none' : ''"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              Adicionar ao Perfil
            </a>
          </div>
        </li>
        <li
          v-for="certificado_habilitado in certificados_habilitados"
          v-show="
            !(
              certificado_habilitado.score_prova_numero == 5 ||
              certificado_habilitado.score_prova_numero == 62 ||
              certificado_habilitado.score_prova_numero == 85 ||
              certificado_habilitado.score_prova_numero == 14 ||
              certificado_habilitado.score_prova_numero == 221 ||
              certificado_habilitado.score_prova_numero == 251
            )
          "
          :key="certificado_habilitado.score_prova_numero"
        >
          <div class="bloco">
            <strong @click="gerarCertificado(certificado_habilitado)">
              {{ certificado_habilitado.prova_nome }}
            </strong>
            <figure
              @click="gerarCertificado(certificado_habilitado)"
              class="courseThumb bgcenter"
              :style="{
                backgroundImage:
                  'url( https://dnc.group/_img/_cover/cover' +
                  certificado_habilitado.prova_certificado_imagem +
                  '.png) , url( https://dnc.group/_img/_cover/sem_imagem.jpg) '
              }"
            ></figure>
            <!-- <a
              class="linkedinLink"
              :href="certificado_habilitado.url_linkedin_share"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
                style="display:inline"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              Compartilhar
            </a> -->
            <a
              class="linkedinLink"
              @click="gerarCertificadoLinkedin(certificado_habilitado)"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
              <p>Adicionar ao Perfil</p>
            </a>
          </div>
        </li>
      </ul>
    </section>
  </div>

  <!-- href="https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=&organizationId=3488494&issueYear=2021&issueMonth=05&certUrl=https://cursos.alura.com.br/certificate/2d4da333-85d0-4060-8b41-91507efa4620&certId=2d4da333-85d0-4060-8b41-91507efa4620" -->
</template>

<script>
// const axios = require("axios");
import { mapState } from "vuex";

export default {
  name: "GerarCertificados",
  computed: {
    ...mapState(["data", "aluno_id"]),
    validacoes() {
      return this.data.validacoes;
    },
    certificados_habilitados() {
      return this.data.certificados_habilitados;
    },
    aluno_nome() {
      return this.data.aluno_nome;
    }
  },
  watch: {
    certificados_habilitados: function() {
      this.loading = false;
      this.verificaValidacoes();
      this.gerarLinkImersao("ip");
    }
  },
  mounted() {},
  data() {
    return {
      ip: false,
      mbp: false,
      sms: false,
      dex: false,
      ppm: false,
      ambevon: false,
      mbp_habilitado: false,
      ip_habilitado: false,
      sms_habilitado: false,
      dex_habilitado: false,
      ppm_habilitado: false,
      ambevon_habilitado: false,
      turma_codigo_ip: null,
      turma_codigo_mbp: null,
      turma_codigo_dex: null,
      turma_codigo_ppm: null,
      turma_codigo_ambevon: null,
      loading: true,
      linkedin_ip: "",
      linkedin_dex: "",
      linkedin_sms: "",
      linkedin_ppm: "",
      linkedin_mbp: "",
      linkedin_ambevon: ""
    };
  },
  methods: {
    gerarLinkImersao() {
      // if (imersao === "ip") {
      //   const certificado = {
      //     prova_nome: "Imersão de Projetos",
      //     turma_codigo: this.turma_codigo_ip,
      //     prova_tipo_id: "5"
      //   };
      //   let link = this.gerarCredencial(certificado);
      //   console.log(link);
      //   this.linkedin_ip = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
      //     certificado.prova_nome
      //   }&organizationId=3488494&issueYear=${
      //     certificado.score_prova_data.split(" ")[0].split("-")[0]
      //   }&issueMonth=${
      //     certificado.score_prova_data.split(" ")[0].split("-")[1]
      //   }&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${link}&certId=${link}`;
      // }
    },
    puxarProvaNome(prova_nome) {
      if (prova_nome.split(" ").length > 1) {
        return prova_nome.split(" ")[1].substring(0, 1);
      } else {
        return prova_nome.split(" ")[0].substring(0, 1);
      }
    },
    gerarCredencial({ prova_nome, turma_codigo, prova_tipo_id }) {
      var idCredencial = null;
      if (
        this.aluno_nome.split(" ")[0] != undefined &&
        this.aluno_nome.split(" ")[1] != undefined
      ) {
        if (turma_codigo) {
          idCredencial =
            prova_tipo_id +
            prova_nome.substring(0, 1) +
            this.puxarProvaNome(prova_nome) +
            this.aluno_nome.substring(0, 1) +
            this.aluno_id +
            this.aluno_nome.split(" ")[1].substring(0, 1) +
            turma_codigo;
        } else {
          idCredencial =
            prova_tipo_id +
            prova_nome.substring(0, 1) +
            this.puxarProvaNome(prova_nome) +
            this.aluno_nome.substring(0, 1) +
            this.aluno_id +
            this.aluno_nome.split(" ")[1].substring(0, 1);
        }
      } else {
        this.$swal.fire({
          title: "Atenção",
          icon: "info",
          text:
            "O seu nome não está preenchido completo para a geração do Certificado! Verifique na área do aluno seu nome completo na área Perfil.",
          footer:
            '<a href="https://aluno.dnc.group/perfil">Acesse seu Perfil por aqui!</a>'
        });
        idCredencial = null;
      }

      return idCredencial;
    },
    gerarCertificadoCiclo(ciclo) {
      if (ciclo.habilitado === false) {
        if (ciclo.tipo === "imersão") {
          this.listarCausasCicloImersao(ciclo);
        } else {
          this.listarCausasCiclo(ciclo);
        }
      } else {
        this.gerarCertificado(ciclo);
      }
    },
    listarCausasCiclo(ciclo) {
      let log = "";
      let title = `Para imprimir seu Certificado de ${ciclo.prova_nome} você deve:`;
      if (ciclo.porcentual_presenca_aluno < ciclo.porcentual_presenca_minima) {
        log +=
          `- Ter no minimo ${ciclo.porcentual_presenca_minima}% de presença. <strong> Sua presença: ` +
          ciclo.porcentual_presenca_aluno +
          "% </strong> </br> ";
      }

      if (ciclo.porcentual_tarefa_aluno < ciclo.porcentual_tarefa_minimo) {
        log +=
          `- Ter no mínimo ${ciclo.porcentual_tarefa_minimo}% de aprovação na entrega de projeto do ciclo. <strong> Sua aprovação: ` +
          ciclo.porcentual_tarefa_aluno +
          "% </strong> </br> ";
      }

      this.$swal({
        icon: "error",
        title: title,
        html: log
      });
    },
    listarCausasCicloImersao(ciclo) {
      let log = "";
      let title = `Para imprimir seu Certificado de ${ciclo.prova_nome} você deve:`;
      if (ciclo.porcentual_entregas_aluno < ciclo.porcentual_entregas_ciclo) {
        log +=
          `- Ter no minimo ${ciclo.porcentual_entregas_ciclo}% de aprovação nas entregas do ciclo. <strong> Sua aprovação nas entregas: ` +
          ciclo.porcentual_entregas_aluno +
          "% </strong> </br> ";
      }

      if (ciclo.porcentual_tarefas_aluno < ciclo.porcentual_tarefas_ciclo) {
        log +=
          `- Ter no mínimo ${ciclo.porcentual_tarefas_ciclo}% de aprovação nas tarefas do ciclo. <strong> Sua aprovação nas tarefas: ` +
          ciclo.porcentual_tarefas_aluno +
          "% </strong> </br> ";
      }

      this.$swal({
        icon: "error",
        title: title,
        html: log
      });
    },
    gerarCertificado(certificado) {
      let dataBruta = certificado.score_prova_data;
      let dataFormatada =
        dataBruta.split("-")[2].split(" ")["0"] +
        "/" +
        dataBruta.split("-")[1] +
        "/" +
        dataBruta.split("-")[0];

      let nomeCurso = certificado.prova_nome;
      let cargaHoraria = certificado.prova_carga;

      let article = {
        aluno_id: this.aluno_id,
        nome: this.aluno_nome,
        link: this.gerarCredencial(certificado),
        nome_curso: nomeCurso,
        data: dataFormatada,
        carga: cargaHoraria,
        descricao: certificado.descricao,
        score_prova_numero: certificado.score_prova_numero
          ? certificado.score_prova_numero
          : 0,
        treinamento_id: certificado.treinamento_id
          ? certificado.treinamento_id
          : 0,
        ciclo: certificado.habilitado ? true : false
      };

      if (article.link == null) {
        // console.log("");
      } else {
        this.$swal.queue([
          {
            title: "Imprimir Certificado?",
            imageUrl:
              "https://dnc.group/_img/_cover/cover" +
              certificado.prova_certificado_imagem +
              ".png",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sim",
            showLoaderOnConfirm: true,
            cancelButtonColor: "#d33",
            reverseButtons: true,
            preConfirm: () => {
              return this.$http
                .post("imprimir/certificado", this.createFormData(article))
                .then(() => {
                  window.open(
                    "https://api.dinamicatreinamentos.com/public/acervo/" +
                      this.gerarCredencial(certificado) +
                      ".pdf",
                    "_blank"
                  );
                })
                .catch(() => {
                  this.$swal.insertQueueStep({
                    icon: "error",
                    title: "Erro inesperado!"
                  });
                });
            }
          }
        ]);
      }
    },
    gerarCertificadoLinkedin(certificado) {
      let dataBruta = certificado.score_prova_data;
      let dataFormatada =
        dataBruta.split("-")[2].split(" ")["0"] +
        "/" +
        dataBruta.split("-")[1] +
        "/" +
        dataBruta.split("-")[0];

      let nomeCurso = certificado.prova_nome;
      let cargaHoraria = certificado.prova_carga;

      let article = {
        aluno_id: this.aluno_id,
        nome: this.aluno_nome,
        link: this.gerarCredencial(certificado),
        nome_curso: nomeCurso,
        data: dataFormatada,
        carga: cargaHoraria,
        descricao: certificado.descricao,
        score_prova_numero: certificado.score_prova_numero
          ? certificado.score_prova_numero
          : 0,
        treinamento_id: certificado.treinamento_id
          ? certificado.treinamento_id
          : 0,
        ciclo: certificado.habilitado ? true : false
      };

      if (article.link == null) {
        // console.log("");
      } else {
        this.$swal.queue([
          {
            title: "Inserir certificado no LinkedIn?",
            imageUrl:
              "https://dnc.group/_img/_cover/cover" +
              certificado.prova_certificado_imagem +
              ".png",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sim",
            showLoaderOnConfirm: true,
            cancelButtonColor: "#d33",
            reverseButtons: true,
            preConfirm: () => {
              return this.$http
                .post("imprimir/certificado", this.createFormData(article))
                .then(() => {
                  if (certificado.habilitado) {
                    this.gerarCertificadoLinkedinCiclo(certificado);
                    window.open(certificado.url_linkedin);
                  } else {
                    window.open(certificado.url_linkedin);
                  }
                })
                .catch(error => {
                  this.$swal.insertQueueStep({
                    icon: "error",
                    title: error
                  });
                });
            }
          }
        ]);
      }
    },
    gerarCertificadoImersao(imersao) {
      if (imersao === "ip") {
        if (this.ip_habilitado) {
          this.gerarCertificadoIP();
        } else {
          this.listarCausasIP();
        }
      } else if (imersao === "sms") {
        if (this.sms_habilitado) {
          this.gerarCertificadoSMS();
        } else {
          this.listarCausasSMS();
        }
      } else if (imersao === "mbp") {
        if (this.mbp_habilitado) {
          this.gerarCertificadoMBP();
        } else {
          this.listarCausasMBP();
        }
      } else if (imersao === "dex") {
        if (this.dex_habilitado) {
          this.gerarCertificadoDEX();
        } else {
          this.listarCausasDEX();
        }
      } else if (imersao === "ppm") {
        if (this.ppm_habilitado) {
          this.gerarCertificadoPPM();
        } else {
          this.listarCausasPPM();
        }
      } else if (imersao === "ambevon") {
        if (this.ambevon_habilitado) {
          this.gerarCertificadoAmbevon();
        } else {
          this.listarCausasAmbevon();
        }
      }
    },
    verificaValidacoes() {
      var possui_imersao = false;

      if (this.validacoes != null) {
        if (this.validacoes.ip) {
          this.ip = true;
          possui_imersao = true;
        }
        if (this.validacoes.sms) {
          this.sms = true;
          possui_imersao = true;
        }
        if (this.validacoes.mbp) {
          this.mbp = true;
          possui_imersao = true;
        }
        if (this.validacoes.dex) {
          this.dex = true;
          possui_imersao = true;
        }
        if (this.validacoes.ppm) {
          this.ppm = true;
          possui_imersao = true;
        }
        if (this.validacoes.ambevon) {
          this.ambevon = true;
          possui_imersao = true;
        }
      }

      if (possui_imersao) {
        this.certificados_habilitados.forEach(certificado => {
          if (certificado.prova_nome != undefined) {
            if (certificado.prova_tipo_id === undefined) {
              certificado.prova_tipo_id = certificado.score_prova_numero;
            }
            let credencial = this.gerarCredencial(certificado);
            certificado.url_linkedin = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              certificado.prova_nome
            }&organizationId=3488494&issueYear=${
              certificado.score_prova_data.split(" ")[0].split("-")[0]
            }&issueMonth=${
              certificado.score_prova_data.split(" ")[0].split("-")[1]
            }&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
            certificado.url_linkedin_share = `https://www.linkedin.com/sharing/share-offsite/?url=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&title=LinkedIn`;
          }

          const meses = [
            "0",
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
          ];

          var data_mes = meses.findIndex(item => {
            return item == certificado.score_prova_data.split(" ")[0];
          });

          if (certificado.score_prova_numero === 5) {
            this.ip_habilitado = true;
            this.turma_codigo_ip = certificado.turma_codigo;
            this.stamps_ip = certificado.stamps;
            let credencial = this.gerarCredencial(certificado);
            this.linkedin_ip = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              certificado.prova_nome
            }&organizationId=3488494&issueYear=${
              certificado.score_prova_data.split(" ")[2]
            }&issueMonth=${data_mes}&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
          }
          if (certificado.score_prova_numero === 14) {
            this.mbp_habilitado = true;
            this.turma_codigo_mbp = certificado.turma_codigo;
            this.stamps_mbp = certificado.stamps;
            let credencial = this.gerarCredencial(certificado);
            this.linkedin_mbp = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              certificado.prova_nome
            }&organizationId=3488494&issueYear=${
              certificado.score_prova_data.split(" ")[2]
            }&issueMonth=${data_mes}&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
          }
          if (certificado.score_prova_numero === 62) {
            this.sms_habilitado = true;
            this.turma_codigo_sms = certificado.turma_codigo;
            this.stamps_sms = certificado.stamps;
            let credencial = this.gerarCredencial(certificado);
            this.linkedin_sms = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              certificado.prova_nome
            }&organizationId=3488494&issueYear=${
              certificado.score_prova_data.split(" ")[2]
            }&issueMonth=${data_mes}&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
          }
          if (certificado.score_prova_numero === 85) {
            this.dex_habilitado = true;
            this.turma_codigo_dex = certificado.turma_codigo;
            this.stamps_dex = certificado.stamps;
            let credencial = this.gerarCredencial(certificado);
            this.linkedin_dex = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              certificado.prova_nome
            }&organizationId=3488494&issueYear=${
              certificado.score_prova_data.split(" ")[2]
            }&issueMonth=${data_mes}&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
          }
          if (certificado.score_prova_numero === 221) {
            this.ppm_habilitado = true;
            this.turma_codigo_ppm = certificado.turma_codigo;
            this.stamps_ppm = certificado.stamps;
            let credencial = this.gerarCredencial(certificado);
            this.linkedin_ppm = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              certificado.prova_nome
            }&organizationId=3488494&issueYear=${
              certificado.score_prova_data.split(" ")[2]
            }&issueMonth=${data_mes}&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
          }
          if (certificado.score_prova_numero === 251) {
            this.ambevon_habilitado = true;
            this.turma_codigo_ambevon = certificado.turma_codigo;
            let credencial = this.gerarCredencial(certificado);
            this.linkedin_ambevon = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              certificado.prova_nome
            }&organizationId=3488494&issueYear=${
              certificado.score_prova_data.split(" ")[2]
            }&issueMonth=${data_mes}&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
          }
        });
      } else {
        this.certificados_habilitados.forEach(certificado => {
          if (certificado.prova_nome != undefined) {
            if (certificado.prova_tipo_id === undefined) {
              certificado.prova_tipo_id = certificado.score_prova_numero;
            }
            let credencial = this.gerarCredencial(certificado);
            certificado.url_linkedin = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              certificado.prova_nome
            }&organizationId=3488494&issueYear=${
              certificado.score_prova_data.split(" ")[0].split("-")[0]
            }&issueMonth=${
              certificado.score_prova_data.split(" ")[0].split("-")[1]
            }&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
            certificado.url_linkedin_share = `https://www.linkedin.com/sharing/share-offsite/?url=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&title=LinkedIn`;
          }
        });
      }
    },
    gerarCertificadoLinkedinCiclo(ciclo) {
      if (ciclo.prova_nome != undefined) {
        if (ciclo.prova_tipo_id === undefined) {
          ciclo.prova_tipo_id = ciclo.score_prova_numero;
        }
        let credencial = this.gerarCredencial(ciclo);
        ciclo.url_linkedin = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
          ciclo.prova_nome
        }&organizationId=3488494&issueYear=${
          ciclo.score_prova_data.split(" ")[0].split("-")[0]
        }&issueMonth=${
          ciclo.score_prova_data.split(" ")[0].split("-")[1]
        }&certUrl=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&certId=${credencial}`;
        ciclo.url_linkedin_share = `https://www.linkedin.com/sharing/share-offsite/?url=https://api.dinamicatreinamentos.com/public/acervo/${credencial}.pdf&title=LinkedIn`;
      }
    },
    listarCausasIP() {
      let log = "";
      let title =
        "Para imprimir seu Certificado de Imersão de Projetos você deve:";
      // participou da banca?
      if (!this.validacoes.ip.presenca_banca) {
        log += "- Participar da banca. </br>";
      }
      // participou do nps?
      if (!this.validacoes.ip.presenca_nps) {
        log += "- Preencher a pesquisa de satisfação final. </br>";
      }
      // +6 presenças?
      if (this.validacoes.ip.presencas < 6) {
        log +=
          "- Ter no minimo 75% de presença para concluir o curso. <strong> Sua presença: " +
          parseInt((this.validacoes.ip.presencas / 7) * 100) +
          "% </strong> </br> ";
      }
      // +1 prova feita?
      if (!this.certificados_habilitados.length) {
        log += "- Fazer ao menos 1 prova.";
      }
      // Quantidade de prova >= 9
      let quantidade_prova =
        this.validacoes.ip.provas_intermediarias_ip +
        this.validacoes.ip.provas_finais_ip;
      if (quantidade_prova < 9) {
        log += "- Ter concluido ao menos 9 provas. </br>";
      }

      this.$swal({
        icon: "error",
        title: title,
        html: log
      });
    },
    listarCausasSMS() {
      let log = "";
      let title =
        "Para imprimir seu Certificado de Sales And Marketing você deve:";
      if (this.validacoes.sms.assignments < 12) {
        log +=
          "- Ter no minimo 12 Tarefas concluidas para concluir o curso. <strong> Suas Tarefas: " +
          parseInt(this.validacoes.sms.assignments) +
          " </strong> </br> ";
      }
      // participou da banca?
      if (!this.validacoes.sms.presenca_banca) {
        log += "- Participar da banca. </br>";
      }
      // participou do nps?
      if (!this.validacoes.sms.presenca_nps) {
        log += "- Preencher a pesquisa de satisfação final. </br>";
      }
      // +6 presenças?
      if (this.validacoes.sms.presencas < 6) {
        log +=
          "- Ter no minimo 75% de presença para concluir o curso. <strong> Sua presença: " +
          parseInt((this.validacoes.sms.presencas / 8) * 100) +
          "% </strong> </br> ";
      }
      // +1 prova feita?
      if (!this.certificados_habilitados.length) {
        log += "- Fazer ao menos 1 prova.";
      }

      this.$swal({
        icon: "error",
        title: title,
        html: log
      });
    },
    listarCausasMBP() {
      let log = "";
      let title =
        "Para imprimir seu Certificado de Master Business Program você deve:";
      // participou da banca?
      if (!this.validacoes.mbp.presenca_banca) {
        log += "- Participar da banca. </br>";
      }
      // participou do nps?
      if (!this.validacoes.mbp.presenca_nps) {
        log += "- Preencher a pesquisa de satisfação final. </br>";
      }
      // +6 presenças? 5 para MBP02
      if (this.validacoes.mbp.turma == "MBP02") {
        if (this.validacoes.mbp.presencas <= 5) {
          log +=
            "- Ter no minimo 75% de presença para concluir o curso. <strong> Sua presença: " +
            parseInt((this.validacoes.mbp.presencas / 7) * 100) +
            "% </strong> </br> ";
        }
      } else {
        if (this.validacoes.mbp.presencas < 6) {
          log +=
            "- Ter no minimo 75% de presença para concluir o curso. <strong> Sua presença: " +
            parseInt((this.validacoes.mbp.presencas / 7) * 100) +
            "% </strong> </br> ";
        }
      }

      // +1 prova feita?
      if (!this.certificados_habilitados.length) {
        log += "- Fazer ao menos 1 prova.";
      }

      this.$swal({
        icon: "error",
        title: title,
        html: log
      });
    },
    listarCausasDEX() {
      let log = "";
      let title = "Para imprimir seu Certificado de Data Expert você deve:";
      // participou da banca?
      if (!this.validacoes.dex.presenca_banca) {
        log += "- Participar da banca. </br>";
      }
      // participou do nps?
      if (!this.validacoes.dex.presenca_nps) {
        log += "- Preencher a pesquisa de satisfação final. </br>";
      }

      if (this.validacoes.dex.assignments < 10) {
        log += "- Ter feito pelo menos 10 assignments. </br>";
      }
      if(this.validacoes.dex.turma === 'DS05' && this.validacoes.dex.deliverables < 9) {
        log += "- Ter entregue todos os deliverables. </br>";
      }

      if(['DEX04', 'DEX03'].includes(this.validacoes.dex.turma) && this.validacoes.dex.deliverables < 11) {
        log += "- Ter entregue todos os deliverables. </br>";
      }

      // +6 presenças? 5 para MBP02
      if (this.validacoes.dex.turma == "MBP02") {
        if (this.validacoes.dex.presencas <= 5) {
          log +=
            "- Ter no minimo 75% de presença para concluir o curso. <strong> Sua presença: " +
            parseInt((this.validacoes.dex.presencas / 7) * 100) +
            "% </strong> </br> ";
        }
      } else {
        if (this.validacoes.dex.presencas < 6) {
          log +=
            "- Ter no minimo 75% de presença para concluir o curso. <strong> Sua presença: " +
            parseInt((this.validacoes.dex.presencas / 7) * 100) +
            "% </strong> </br> ";
        }
      }

      // +1 prova feita?
      if (!this.certificados_habilitados.length) {
        log += "- Fazer ao menos 1 prova.";
      }

      this.$swal({
        icon: "error",
        title: title,
        html: log
      });
    },
    listarCausasPPM() {
      let log = "";
      let title =
        "Para imprimir seu Certificado de Formação em Produto você deve:";
      // participou da banca?
      if (!this.validacoes.ppm.presenca_banca) {
        log += "- Participar da banca. </br>";
      }
      if (
        !this.validacoes.ppm.presenca_nps &&
        this.validacoes.ppm.turma_id >= 68
      ) {
        log += "- Preencher a pesquisa de satisfação final. <br/>";
      }

      if (this.validacoes.ppm.deliverables_aluno_ppm < 5) {
        log += "- Ter feito todos os deliverables. </br>";
      }

      // 26 presenças
      if (this.validacoes.ppm.presencas < 26) {
        log +=
          "- Ter no minimo 75% de presença. <strong> Sua presença: " +
          parseInt((this.validacoes.ppm.presencas / 34) * 100) +
          "% </strong> </br> ";
      }

      // +1 prova feita?
      if (this.validacoes.ppm.provas_intermediarias_ppm < 9) {
        log += "- Tirar ao menos 9 certificados.";
      }

      this.$swal({
        icon: "error",
        title: title,
        html: log
      });
    },
    listarCausasAmbevon() {
      let log = "";
      let title =
        "Para imprimir seu Certificado de Formação em Project Manager Full Stack você deve:";
      // participou da banca?
      if (!this.validacoes.ambevon.presenca_banca) {
        log += "- Ter Presença na banca. </br>";
      }
      if (!this.validacoes.ambevon.banca) {
        log += "- Ter participado da banca. </br>";
      }

      this.$swal({
        icon: "error",
        title: title,
        html: log
      });
    },

    gerarCertificadoIP() {
      const certificado = {
        prova_nome: "Imersão de Projetos",
        turma_codigo: this.turma_codigo_ip,
        prova_tipo_id: 5
      };
      this.$swal.queue([
        {
          title: "Imprimir Certificado?",
          imageUrl: "https://dnc.group/_img/_cover/cover20.jpg",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          cancelButtonColor: "#d33",
          reverseButtons: true,
          preConfirm: () => {
            return this.$http
              .post(
                "imprimir/certificado/ip",
                this.createFormData({
                  aluno_id: this.aluno_id,
                  nome: this.aluno_nome,
                  link: this.gerarCredencial(certificado),
                  stamps: this.stamps_ip,
                  turma: this.turma_codigo_ip,
                  prova_tipo_id: 5
                })
              )
              .then(() => {
                let url = "https://api.dinamicatreinamentos.com/";
                // let url = "https://api.dev/";
                window.open(
                  url +
                    "public/acervo/" +
                    this.gerarCredencial(certificado) +
                    ".pdf",
                  "_blank"
                );
              })
              .catch(() => {
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Erro inesperado!"
                });
              });
          }
        }
      ]);
    },
    gerarCertificadoSMS() {
      const certificado = {
        prova_nome: "Imersão de Projetos",
        turma_codigo: this.turma_codigo_sms,
        prova_tipo_id: 62
      };
      this.$swal.queue([
        {
          title: "Imprimir Certificado?",
          imageUrl: "https://dnc.group/_img/_cover/cover79.jpg",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          cancelButtonColor: "#d33",
          reverseButtons: true,
          preConfirm: () => {
            return this.$http
              .post(
                "imprimir/certificado/sms",
                this.createFormData({
                  aluno_id: this.aluno_id,
                  nome: this.aluno_nome,
                  link: this.gerarCredencial(certificado),
                  stamps: this.stamps_sms,
                  turma: this.turma_codigo_sms,
                  prova_tipo_id: 62
                })
              )
              .then(() => {
                let url = "https://api.dinamicatreinamentos.com/";
                // let url = "https://api.dev/";
                window.open(
                  url +
                    "public/acervo/" +
                    this.gerarCredencial(certificado) +
                    ".pdf",
                  "_blank"
                );
              })
              .catch(() => {
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Erro inesperado!"
                });
              });
          }
        }
      ]);
    },
    gerarCertificadoMBP() {
      const certificado = {
        prova_nome: "Master Business Program",
        turma_codigo: this.turma_codigo_mbp,
        prova_tipo_id: 14
      };

      this.$swal.queue([
        {
          title: "Imprimir Certificado?",
          imageUrl: "https://dnc.group/_img/_cover/cover21.jpg",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          cancelButtonColor: "#d33",
          reverseButtons: true,
          preConfirm: () => {
            return this.$http
              .post(
                "imprimir/certificado/mbp",
                this.createFormData({
                  aluno_id: this.aluno_id,
                  nome: this.aluno_nome,
                  link: this.gerarCredencial(certificado),
                  stamps: this.stamps_mbp,
                  turma: this.turma_codigo_mbp,
                  prova_tipo_id: 14
                })
              )
              .then(() => {
                let url = "https://api.dinamicatreinamentos.com/";
                // let url = "https://api.dev/";
                window.open(
                  url +
                    "public/acervo/" +
                    this.gerarCredencial(certificado) +
                    ".pdf",
                  "_blank"
                );
              })
              .catch(() => {
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Erro inesperado!"
                });
              });
          }
        }
      ]);
    },
    gerarCertificadoDEX() {
      const certificado = {
        prova_nome: "Data Expert",
        turma_codigo: this.turma_codigo_dex,
        prova_tipo_id: 85
      };

      this.$swal.queue([
        {
          title: "Imprimir Certificado?",
          imageUrl: "https://dnc.group/_img/_cover/cover57.jpg",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          cancelButtonColor: "#d33",
          reverseButtons: true,
          preConfirm: () => {
            return this.$http
              .post(
                "imprimir/certificado/dex",
                this.createFormData({
                  aluno_id: this.aluno_id,
                  nome: this.aluno_nome,
                  link: this.gerarCredencial(certificado),
                  stamps: this.stamps_dex,
                  turma: this.turma_codigo_dex,
                  prova_tipo_id: 85
                })
              )
              .then(() => {
                let url = "https://api.dinamicatreinamentos.com/";
                // let url = "https://api.dev/";
                window.open(
                  url +
                    "public/acervo/" +
                    this.gerarCredencial(certificado) +
                    ".pdf",
                  "_blank"
                );
              })
              .catch(() => {
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Erro inesperado!"
                });
              });
          }
        }
      ]);
    },
    gerarCertificadoPPM() {
      const certificado = {
        prova_nome: "Formação em Projeto",
        turma_codigo: this.turma_codigo_ppm,
        prova_tipo_id: 221
      };

      this.$swal.queue([
        {
          title: "Imprimir Certificado?",
          imageUrl: "https://dnc.group/_img/_cover/cover780.png",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          cancelButtonColor: "#d33",
          reverseButtons: true,
          preConfirm: () => {
            return this.$http
              .post(
                "imprimir/certificado/ppm",
                this.createFormData({
                  aluno_id: this.aluno_id,
                  nome: this.aluno_nome,
                  link: this.gerarCredencial(certificado),
                  stamps: this.stamps_ppm,
                  turma: this.turma_codigo_ppm,
                  prova_tipo_id: 221
                })
              )
              .then(() => {
                let url = "https://api.dinamicatreinamentos.com/";
                // let url = "https://api.dev/";
                window.open(
                  url +
                    "public/acervo/" +
                    this.gerarCredencial(certificado) +
                    ".pdf",
                  "_blank"
                );
              })
              .catch(() => {
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Erro inesperado!"
                });
              });
          }
        }
      ]);
    },
    gerarCertificadoAmbevon() {
      const certificado = {
        prova_nome: "Project Manager Full Stack",
        turma_codigo: this.turma_codigo_ambevon,
        prova_tipo_id: 251
      };

      this.$swal.queue([
        {
          title: "Imprimir Certificado?",
          imageUrl: "https://dnc.group/_img/_cover/cover251.png",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          cancelButtonColor: "#d33",
          reverseButtons: true,
          preConfirm: () => {
            return this.$http
              .post(
                "imprimir/certificado/ambevon",
                this.createFormData({
                  aluno_id: this.aluno_id,
                  nome: this.aluno_nome,
                  link: this.gerarCredencial(certificado),
                  stamps: [],
                  turma: this.turma_codigo_ambevon,
                  prova_tipo_id: 251
                })
              )
              .then(() => {
                let url = "https://api.dinamicatreinamentos.com/";
                // let url = "https://api.dev/";
                window.open(
                  url +
                    "public/acervo/" +
                    this.gerarCredencial(certificado) +
                    ".pdf",
                  "_blank"
                );
              })
              .catch(() => {
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Erro inesperado!"
                });
              });
          }
        }
      ]);
    },
    createFormData(article) {
      var formData = new FormData();
      formData.append("data", JSON.stringify(article));
      return formData;
    }
  }
};
</script>

<style scoped>
.withoutCertificate {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
}
.textWithoutCertificate {
  font: gotham-re !important;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  padding-top: 108px;
}
.linkWithoutCertificate {
  font: gotham-re;
  font-weight: 500;
  padding-bottom: 108px;
  font-size: 18px;
  opacity: 0.9;
}
.linkStartCourse {
  text-decoration: underline;
  font-size: 18px;
  opacity: 1;
}

.linkedinLink {
  color: #0c70f1;
  margin: 10px;
  display: flex;
  align-items: center;
  padding: 3px;
  justify-content: center;
  height: 20px;
}

.linkedinLink:hover * {
  color: silver;
  text-decoration: italic;
}

.linkedinLink p {
  font-size: 16px;
  color: #0c70f1;
  font-family: gotham-re;
  font-weight: bold;
  min-height: 20px;
  padding: 3px;
}

.bi {
  margin-right: 3px;
}

.rodar img {
  filter: saturate(100%) brightness(0%);
  opacity: 0.5;
  animation-name: spin;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.courseListWrapper {
  flex-wrap: wrap;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}
</style>
