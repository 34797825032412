<template>
  <div>
    <header class="soft-top-header">
      <a href="http://aluno.dnc.group/painel">  
        <img src="https://aluno.dnc.group/_nuxt/img/logo_dark_100.d55b0df.png" alt="DNC" class="dnc-logo" data-v-6de150ee="">
      </a>
    </header>
  </div>
</template>

<script>
 /* eslint-disable */
export default {
  name: "Header"
};
</script>

<style scoped>
.soft-top-header {
  padding: 25px;
}
@media screen and (max-width: 1080px) {
  .passoDescribe {
    flex-direction: column;
    align-items: flex-start;
  }
  .describe {
    margin: 10px 0;
  }
}
</style>
