import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    data: {},
    aluno_id: null
  },
  mutations: {
    FETCH_DATA(state, payload) {
      state.data = payload;
    },
    UPDATE_ALUNO(state, payload) {
      state.aluno_id = payload;
    }
  }
});
